import { Injectable } from '@angular/core';
import {
  PdfConfig,
  PdfTableLayout
} from './pdf-definitions';

const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
const htmlToPdfMake = require('html-to-pdfmake');

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  'Roboto': {
    normal: `${window.location.origin}/assets/fonts/Roboto/Roboto-Regular.ttf`,
    bold: `${window.location.origin}/assets/fonts/Roboto/Roboto-Medium.ttf`,
    italics: `${window.location.origin}/assets/fonts/Roboto/Roboto-Italic.ttf`,
    bolditalics: `${window.location.origin}/assets/fonts/Roboto/Roboto-MediumItalic.ttf`
  }
};

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor() { }

  /**
   * Returns the predefined PDFMake document definition for claim notes
   * containing important sections (ie Header, Content, Footer).
   *
   * @param config See {@link: PdfConfig}
   * @param documentDetails The actual content of the document
   */
  getNotesDocumentDefinition(config: PdfConfig, documentDetails: any) {
    const additionalFooterText = `${config.claimantName} // ${config.claimNumber}`;
    let contentTitle = [];
    if (config && config.contentTitle) {
      contentTitle = [
        { text: config.contentTitle, bold: true, fontSize: 14 },
        { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 545, y2: 0, lineWidth: 1 }] },
        htmlToPdfMake('&#8192; </br>')
      ];
    }
    return {
      header: {
        stack: [
          { text: config.pageTitle, bold: true, fontSize: 18 },
          {
            table: {
              widths: ['*', 45, 90],
              body: [
                [
                  { rowSpan: 2, text: config.url, style: 'subheader' },
                  { text: 'Claimant:', style: 'subheader' },
                  { text: config.claimantName, style: 'subheader', alignment: 'right' }
                ],
                [
                  '',
                  { text: 'Claim #:', style: 'subheader' },
                  { text: config.claimNumber, style: 'subheader', bold: true, alignment: 'right' }
                ]
              ]
            },
            layout: PdfTableLayout.NO_BORDERS
          }
        ],
        margin: 25
      },
      footer: function (currentPage, pageCount, additionalText) {
        const pageNumber = 'page ' + currentPage.toString() + ' of ' + pageCount;
        additionalText = additionalFooterText ? additionalFooterText : '';
        return {
          columns: [
            { text: additionalText, style: 'footer', width: 470, alignment: 'right' },
            { text: pageNumber, style: 'footer', width: '*' }
          ]
        };
      },
      content: [
        ...contentTitle,
        ...documentDetails
      ],
      styles: {
        'subheader': { fontSize: 9 },
        'content': { fontSize: 9, lineHeight: 1.5, margin: [0, 0, 20, 0] },
        'note-div': { fontSize: 9, lineHeight: 1.5, margin: [0, 10, 0, 10] },
        'footer': { fontSize: 7 },
        'html-p': { fontSize: 9, lineHeight: 1.5, margin: [0, 0, 20, 0] }
      },
      pageMargins: [25, 120, 25, 40],
      pageSize: 'LETTER',
      defaultStyle: {
        columnGap: 10,
        font: 'Roboto'
      }
    };
  }

  /**
   * Returns the predefined PDFMake document definition for dynamic panel
   * containing important sections (ie Header, Content, Footer).
   *
   * @param config See {@link: PdfConfig}
   */
  getDynamicPanelDocumentDefinition(config: PdfConfig) {
    if (!config) {
      return;
    }

    const additionalFooterText = `${config.downloadDate}\t ${config.claimantName} // ${config.claimNumber}`;
    let contentTitle = {};
    if (config.pageTitle) {
      contentTitle = {
        header: {
          stack: [
            { text: config.pageTitle, bold: true, fontSize: 18 },
            {
              table: {
                widths: ['*', 45, 90],
                body: [
                  [
                    { rowSpan: 2, text: config.url, style: 'subheader' },
                    { text: 'Claimant:', style: 'subheader' },
                    { text: config.claimantName, style: 'subheader', alignment: 'right' }
                  ],
                  [
                    '',
                    { text: 'Claim #:', style: 'subheader' },
                    { text: config.claimNumber, style: 'subheader', bold: true, alignment: 'right' }
                  ]
                ],
              },
              layout: PdfTableLayout.NO_BORDERS
            }
          ],
          margin: 25
        }
      };
    }

    const contents = [];
    if (config.contents && config.contents.length > 0) {
      contents.push(...config.contents);
    }
    let firstSection = true;
    return {
      ...contentTitle,
      footer: function (currentPage, pageCount, additionalText) {
        const pageNumber = 'page ' + currentPage.toString() + ' of ' + pageCount;
        additionalText = additionalFooterText ? additionalFooterText : '';
        return {
          columns: [
            { text: additionalText, style: 'footer', width: 470, alignment: 'right' },
            { text: pageNumber, style: 'footer', width: '*' }
          ]
        };
      },
      content: [
        ...contents
      ],
      styles: {
        'header': { fontSize: 14, bold: true },
        'subheader': { fontSize: 9 },
        'label': { fontSize: 9, lineHeight: 1.5, bold: true },
        'p': { fontSize: 9, lineHeight: 1.5 },
        'subfield': { fontSize: 8, lineHeight: 1.5 },
        'content': { fontSize: 9, lineHeight: 1.5, margin: [0, 0, 20, 0] },
        'note-div': { fontSize: 9, lineHeight: 1.5, margin: [0, 10, 0, 10] },
        'footer': { fontSize: 7 },
        'html-p': { fontSize: 9, lineHeight: 1.5, margin: [0, 0, 20, 0] },
        'paid-ratio': { fontSize: 9, lineHeight: 2, alignment: 'left' }
      },
      pageMargins: [25, 120, 25, 40],
      pageSize: 'LETTER',
      defaultStyle: {
        columnGap: 10,
        font: 'Roboto'
      },
      pageBreakBefore: function(currentNode: any, followingNodesOnPage: any) {
        // NOTE: currentNode.pageNumbers.length if greater than 1 means it spans in more than 1 page.
        if (currentNode && currentNode.id) {
          const [type, subtype] = currentNode.id.split('-');
          switch (type) {
            case 'section':
              if (firstSection) { // if it is firstSection to render, don't put page break
                firstSection = false; // set firstSection to false so that it will not hit this line of code after the first section is rendered.
                return false;
              }
              return currentNode.pageNumbers.length > 1;
            case 'note': // if it is a note check if pageNumbers is greater than 1
            case 'field': // if it is a field check if pageNumbers is greater than 1
              return currentNode.pageNumbers.length > 1;
            default: return false;
          }
        }
        return false;
      }
    };
  }

  /**
   * Exports the data as PDF
   * @param documentDefinition
   * @param filename
   */
  exportAsPDF(documentDefinition: any, filename: string) {
    pdfMake.createPdf(documentDefinition).download(filename);
  }
}
